<template>
    <div class="login_main">
        <div class="header">
            <img src="../../assets/img/shop_logo.png" class="logo" @click="$router.replace('/index')" />
        </div>

        <div class="main">
            <div class="content">
                <img :src="backendImage ? imageURL + backendImage : require('../../assets/img/login_img.png')" class="img" />

                <div class="form" style="width: 520px">
                    <form>
                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_phone.png" width="16" height="20" />
                                </div>
                                <input v-model="form.phone" placeholder="请输入您的手机号" class="formInput" @keyup.enter="goVerifyCode" />
                                <input style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                            </div>
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_code.png" width="16" height="20" />
                                </div>
                                <input type="password"  style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                                <input v-model="form.imgCode" autocomplete="imgCode" placeholder="请输入图形验证码" class="formInput" @keyup.enter="goVerifyCode" />
                                <input style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                            </div>
                            <img :src="codeImage" class="codeBtn" @click="goImgCode" style="cursor: pointer" />
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_code.png" width="16" height="20" />
                                </div>
                                <input type="password"  style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                                <input v-model="form.code" autocomplete="off" placeholder="请输入验证码" class="formInput" @keyup.enter="goVerifyCode" />
                                <input  style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                            </div>
                            <el-button :class="isSendCode ? 'on' : ''" class="codeBtn" :loading="isRequestSend" @click="toSendCode">{{isSendCode ? smsBtnText : '获取验证码'}}</el-button>
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_password.png" width="16" height="20" />
                                </div>
                                <input v-model="form.password" :type="passwordType" autocomplete="off" placeholder="请设置密码（8-20位，包含字母和数字）" class="formInput" @focus="passwordType = 'password'" @keyup.enter="goVerifyCode" />
                            </div>
                        </div>

                        <div class="formItem">
                            <div class="inputItem">
                                <div class="icon">
                                    <img src="../../assets/img/login_user.png" width="16" height="20" />
                                </div>
                                <input v-model="form.nickname" placeholder="请输入您的昵称" class="formInput" @keyup.enter="goVerifyCode" />
                            </div>
                        </div>

                        <div class="formItem">
                            <el-button type="primary" class="loginBtn" :loading="isRequestLoading" @click="goVerifyCode">立即注册</el-button>
                        </div>
                    </form>

                    <div class="else">
                        <div style="flex: 1">
                            <el-checkbox v-model="checked">
                                <span style="color: #666">我已阅读并同意</span>
                            </el-checkbox>
                            <template v-for="(item, i) in pactData">
                                <template v-if="Number(i) > 0">与</template>
                                <span class="item ml0" style="cursor: pointer" @click="toShowPact(item.id)" :key="i">《{{item.title}}》</span>
                            </template>
                        </div>
                        <div class="text">已有帐号？<a href="javascript:void(0)" @click="$router.replace('/login')">登录</a></div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="isPopup" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close="true" top="5vh" class="popupRegister">
            <div class="protocolMain">
                <div v-html="pactInfo"></div>

                <div class="article_detail_file" v-if="fileJson.length > 0">
                    <div class="text">下载</div>

                    <div class="list">
                        <a class="item" v-for="(item, index) in fileJson" :key="index" target="_blank" :href="imageURL + item.url">
                            <span>{{index + 1}}、{{item.name}}</span><img src="../../assets/img/download.png" />
                        </a>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="isPopup = false" size="small">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
  import {smsSend, smsVerify, register, postImgCode, getBackendLogin} from '../../api/public'
  import {regular_phone, regular_password} from '../../utils/regular'
  import {baseURL, imageURL} from "../../utils/config";
  import {getArticle, getArticleInfo} from "../../api/shop/article";
  export default {
    name: "Register",
    data () {
      return {
        imageURL: imageURL,
        form: {
          phone: '',
          imgCode: '',
          code: '',
          password: '',
          nickname: ''
        },
        isRequestLoading: false,
        isRequestSend: false,
        isSendCode: false,
        checked: false,
        smsBtnText: '',
        isPopup: false,
        passwordType: 'text',
        codeNum: 0,
        codeImage: '',
        dateTimes: null,
        pactData: [],
        pactInfo: '',
        fileJson: [],
        backendImage: ''
      }
    },
    created() {
      this.dateTimes = new Date().getTime();
      this.goImgCode();
      this.getList();
      this.getBackendLogin()
    },
    methods: {
      getList () {
        getArticle({
          type: 3,
          page: 1,
          pageSize: 20
        }).then(res => {
          if (res.code === 0) {
            this.pactData = res.data.records
          }
        })
      },
      goImgCode () {
        this.codeNum++
        this.codeImage = `${baseURL}/kaptcha/getImageCode/${this.dateTimes}?type=${this.codeNum}`
      },
      getBackendLogin () {
        getBackendLogin().then(res => {
          if (res.code === 0) {
            this.backendImage = res.data.fileName
          }
        })
      },
      toSendCode () {
        if (this.isSendCode) {
          return false
        }
        if (this.form.phone === '') {
          this.$message({
            message: '请输入您的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.imgCode === '') {
          this.$message({
            message: '请输入图形验证码',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (!regular_phone.test(this.form.phone)) {
          this.$message({
            message: '请输入正确的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }

        postImgCode({
          data: {
            code: this.form.imgCode,
            uuid: this.dateTimes
          }
        }).then(res => {
          if (res.code === 0) {
            this.getCode()
          } else {
            this.goImgCode()
          }
        }).catch(() => {
          this.goImgCode()
        })
      },
      getCode () {
        this.isRequestSend = true
        smsSend({
          data: {
            phone: this.form.phone,
            type: 1
          }
        }).then(res => {
          this.isRequestSend = false
          if(res.code === 0) {
            this.isSendCode = true
            this.toChangeSmsTime(60)
          }
        }).catch(() => {
          this.isRequestSend = false
        })
      },
      toChangeSmsTime (time) {
        if (time === 0) {
          this.isSendCode = false
        } else {
          this.isSendCode = true
          this.smsBtnText = '重新获取(' + time + 's)'
          let times = time - 1
          let that = this
          setTimeout(() => {
            that.toChangeSmsTime(times)
          }, 1000)
        }
      },
      goVerifyCode () {
        if (!this.checked) {
          let text = ''
          this.pactData.forEach((item, index) => {
            if (Number(index) === 0) {
              text = '《' + item.title + '》'
            } else {
              text = text + '与《' + item.title + '》'
            }
          })
          this.$message({
            message: '请阅读并同意' + text,
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.phone === '') {
          this.$message({
            message: '请输入您的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.imgCode === '') {
          this.$message({
            message: '请输入图形验证码',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.code === '') {
          this.$message({
            message: '请输入验证码',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.password === '') {
          this.$message({
            message: '请设置密码',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (!regular_password.test(this.form.password)) {
          this.$message({
            message: '请设置密码（8-20位，包含字母和数字）',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (this.form.nickname === '') {
          this.$message({
            message: '请输入您的昵称',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        this.isRequestLoading = true
        smsVerify({
          data: {
            phone: this.form.phone,
            type: 1,
            code: this.form.code
          }
        }).then(res => {
          if (res.code === 0) {
            this.toRegister()
          } else {
            this.isRequestLoading = false
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      toRegister () {
        register({
          data: {
            phone: this.form.phone,
            password: this.form.password,
            nickname: this.form.nickname,
          }
        }).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.$confirm('恭喜您已注册成功，是否立即登录?', '提示', {
              confirmButtonText: '前往登录',
              cancelButtonText: '返回首页',
              closeOnClickModal: false,
              type: 'success'
            }).then(() => {
              this.$router.replace('/login')
            }).catch(() => {
              this.$router.replace('/index')
            });
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      toShowPact (id) {
        this.isPopup = true
        getArticleInfo(id).then(res => {
          if (res.code === 0) {
            this.pactInfo = res.data.detail;
            this.fileJson = res.data.fileJson
          }
        })
      }
    }
  }
</script>
