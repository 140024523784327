import {request} from '@/utils/request'

// 发送验证码
export function smsSend(data) {
  return request('POST', '/sms/send', data, false, true)
}

// 验证验证码
export function smsVerify(data) {
  return request('POST', '/sms/verify', data, false, true)
}

// 注册
export function register(data) {
  return request('POST', '/users/SMSRegistered', data, false, true)
}

// 登录
export function login(data) {
  return request('POST', '/oauth/token', data, false, false)
}

// 用户信息
export function getUserInfo(data) {
  return request('POST','/shop/users/getUsersInfo', data, true, true)
}

// 用户密码
export function postUserPassword(data) {
  return request('POST','/shop/users/putUserPassword', data, true, true)
}

// 忘记密码
export function postUserForget(data) {
  return request('POST','/users/putUserPassword', data, true, true)
}

// 用户手机号
export function postUserPhone(data) {
  return request('POST','/shop/users/putUserPhone', data, true, true)
}

// 菜单
export function getGoodsMenu(data) {
  return request('GET','/menu', data, true, true)
}

// 导航
export function getNav(data) {
  return request('GET','/navigation', data, true, true)
}

// 热门搜索
export function getSearchHot(data) {
  return request('GET','/searchHeat', data, true, true)
}

// 默认搜索
export function getSearchDefault(data) {
  return request('GET','/searchSite', data, true, true)
}

// 验证图形验证码
export function postImgCode(data) {
  return request('POST', '/kaptcha/checkCode', data, false, true)
}

// 检查是否有支付密码
export function getIsPayPassword(data) {
  return request('GET','/shop/users/payPassword', data, true, true)
}

// 修改支付密码
export function postIsPayPassword(data) {
  return request('PUT','/shop/users/payPassword', data, true, true)
}

// 重置支付密码
export function postIsPayPasswordReset(data) {
  return request('PUT','/shop/users/payPassword/reset', data, true, true)
}

// 注销
export function postExit(data) {
  return request('POST', '/shop/user/cancel/apply', data, true, true)
}

// 登录背景
export function getBackendLogin() {
  return request('GET','/background/image', {}, false, true)
}

